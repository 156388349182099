<template lang="pug">
div(v-if="customStyle.popup_text")
  .effective-ranta(
    :style="{background: customStyle.popup_bg_color, color: customStyle.popup_text_color}"
  )
    .effective-ranta-wrapper
      | {{ customStyle.popup_text }}

</template>

<script>
import {mapState, } from 'vuex'

export default {
  computed: {
    ...mapState('reseller', {
      customStyle: state => state.customStyle.accessoryPage || {},
    }),
  }
}
</script>

<style lang="sass">
  @import '@/style/components/EffectiveRanta.sass'
</style>